import React from 'react'
import get from 'lodash/get'
import { siteMetadata } from '../../../gatsby-config'
import BackgroundImage from 'gatsby-background-image'

import Navi from 'components/navi/navi'
import './carousel-header.scss'

export default function CarouselHeader(props) {
  // Building out Carousel Slides.
  const carouselSlides = props.carouselItems
    .filter((slide_data) => {
      // Filter out empty Banner Slides, just in case.
      if (slide_data.field_slide_image === undefined || slide_data.field_slide_image === null) {
        return false
      }

      return true
    })
    .map((slide_data, index) => (
      <CarouselSlide
        {...slide_data}
        key={slide_data.drupal_internal__id}
        active={index === 0 ? true : false}
      />
    ))

  return (
    <header className="header-wrapper">
      <Navi title={get(siteMetadata, 'title')} {...props} />

      <div id="headerCarousel" className="carousel slide" data-ride="carousel">
        <div className="carousel-inner">{carouselSlides}</div>
        <a className="carousel-control-prev" href="#headerCarousel" role="button" data-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="sr-only">Previous</span>
        </a>
        <a className="carousel-control-next" href="#headerCarousel" role="button" data-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="sr-only">Next</span>
        </a>
      </div>
    </header>
  )
}

function CarouselSlide(props) {
  let carouselContent = props.field_slide_content ? props.field_slide_content.processed : undefined
  let carouselCta = props.field_slide_cta
  const bannerImg = get(props, 'relationships.field_slide_image.localFile.childImageSharp.fluid')

  return (
    <div className={`carousel-item ${props.active && 'active'}`}>
      <BackgroundImage Tag="div" fluid={bannerImg} alt={props.field_slide_image.alt}>
        <div className="carousel-content-wrapper">
          <div className="container-fluid">
            <div className="carousel-caption text-left">
              <h1>{props.field_slide_title}</h1>
              {carouselContent && (
                <div
                  className="lead"
                  dangerouslySetInnerHTML={{
                    __html: props.field_slide_content.processed,
                  }}
                />
              )}
              {carouselCta && (
                <p>
                  <a className="btn btn-lg btn-outline-light" href={carouselCta.url} role="button">
                    {carouselCta.title}
                  </a>
                </p>
              )}
            </div>
          </div>
        </div>
      </BackgroundImage>
    </div>
  )
}
