import { getParagraph } from 'components/paragraph/paragraph-factory'
import { graphql } from 'gatsby'
import React from 'react'
import { get, has } from 'lodash'

import CarouselLayout from 'components/layout/carousel-layout'
import Meta from 'components/meta/meta'

const Homepage = ({ data, location }) => {
  const carouselItems = get(data, 'homepage.relationships.field_homepage_carousel', [])

  let mainContent = get(data, 'homepage.field_main_content', '')
  if (mainContent && has(mainContent, 'processed')) {
    const drupalUrl = process.env.DRUPAL_URL || 'https://ispe.org'
    console.log('DRUPAL URL FALLBACK: ', drupalUrl)
    mainContent = mainContent.processed.replace(
      /\/sites\/default\/files/g,
      drupalUrl + '/sites/default/files'
    )
  }

  let contentParagraphs = get(data, 'homepage.relationships.contentParagraphs', [])
  contentParagraphs = contentParagraphs.map(getParagraph)

  return (
    <CarouselLayout location={location} carouselItems={carouselItems}>
      <Meta site={get(data, 'site.meta')} />
      <main>
        {/* Home Content. */}
        <section className="home-content">
          <div className="container">
            <div className="row mb-5 mt-2 home-content-logo">
              <div className="col">
                <img
                  src="img/ispe-foundation-logo.png"
                  className="mx-auto d-block"
                  alt="ispe-foundation-logo"
                />
              </div>
            </div>

            <div className="row mx-auto text-center">
              <div className="col">
                <div dangerouslySetInnerHTML={{ __html: mainContent }} />
              </div>
            </div>
          </div>
        </section>

        {/* Content Paragraphs. */}
        <div className="content-paragraphs">{contentParagraphs}</div>

        {/*/!* Testimonials. *!/*/}
        {/*<section className="container">*/}
        {/*  <blockquote className="blockquote text-center">*/}
        {/*    <p className="mb-0">*/}
        {/*      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.*/}
        {/*    </p>*/}
        {/*    <footer className="blockquote-footer">*/}
        {/*      Someone famous in <cite title="Source Title">Source Title</cite>*/}
        {/*    </footer>*/}
        {/*  </blockquote>*/}
        {/*</section>*/}
      </main>
    </CarouselLayout>
  )
}

export default Homepage

export const pageQuery = graphql`
  query FrontpageQuery {
    site {
      meta: siteMetadata {
        title
        description
        url: siteUrl
        drupalUrl
        author
        twitter
      }
    }
    homepage: configPagesFoundationHomepage {
      field_main_content {
        processed
        summary
      }
      relationships {
        contentParagraphs: field_content_blocks {
          type: __typename
          ... on paragraph__featured_content_collection {
            ...ParagraphFeaturedContentItemsFragment
          }
          ... on paragraph__icon_collection {
            ...ParagraphIconCollectionFragment
          }
          ... on paragraph__webform_embed {
            ...ParagraphWebformEmbedFragment
          }
        }
        field_homepage_carousel {
          field_slide_title
          field_slide_content {
            value
            processed
          }
          field_slide_cta {
            title
            url
          }
          field_slide_image {
            title
            alt
          }
          drupal_internal__id
          relationships {
            field_slide_image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2000, srcSetBreakpoints: [576, 768, 992, 1200]) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
