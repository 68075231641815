import React from 'react'
import emergence from 'emergence.js'

import CarouselHeader from 'components/header/carousel-header'
import Footer from 'components/footer/footer'
import { siteMetadata } from '../../../gatsby-config'

class CarouselLayout extends React.Component {
  componentDidMount() {
    emergence.init()
  }

  componentDidUpdate() {
    emergence.init()
  }

  render() {
    const { children } = this.props
    return (
      <div>
        <CarouselHeader title={siteMetadata.title} {...this.props} />
        {children}
        <Footer />
      </div>
    )
  }
}

export default CarouselLayout
